import React from "react";

//MUI

//Components
//import Header from "./components/Header";
import LandingTop from "./components/LandingTop";
import Provide from "./components/Provide";
import Team from "./components/Team";
import Footer from "./components/Footer";

//Styles
import "./Style/App.css";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <LandingTop />
      <Provide />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
