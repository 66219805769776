import React from "react";

//MUI
import Typography from "@mui/material/Typography";

const Footer = (props) => {
  return (
    <footer style={{ backgroundColor: "#1976d2", padding: 20 }}>
      <Typography
        variant="h6"
        noWrap
        sx={{
          mr: 2,
          color: "inherit",
          textDecoration: "none",
        }}
      >
        4N Productions LLC
      </Typography>
      <Typography
        variant="body1"
        noWrap
        sx={{
          mr: 2,
          color: "inherit",
          textDecoration: "none",
          padding: 3,
        }}
      >
        2022
      </Typography>
      <Typography
        variant="caption"
        noWrap
        href="mailto:nguyen@petertrung.com"
        sx={{
          mr: 2,
          color: "inherit",
          textDecoration: "none",
          marginBottom: 6,
        }}
      >
        Nguyen@petertrung.com
      </Typography>
    </footer>
  );
};
//W/y/1riRpY4Hs6f9xsjcFSH6uwqFnwwzI42fZNm0

export default Footer;
