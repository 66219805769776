import React from "react";

//MUI
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

//Icon
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";

//Style
import "../Style/index.css";

const Provide = () => {
  return (
    <div style={{ minHeight: "50vh", padding: 40 }}>
      <div>
        <Typography
          variant="h4"
          sx={{
            mr: 2,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Services We Provide
        </Typography>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={9}>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{
                  bgcolor: "#08415C",
                  width: { xs: "25vw", md: "5vw" },
                  height: { xs: "25vw", md: "5vw" },
                  margin: 3,
                }}
              >
                <ComputerOutlinedIcon fontSize="large" />
              </Avatar>
              <Typography
                variant="h4"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                  marginBottom: 2,
                }}
              >
                Web
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                A good web presence can help launch your business and get you
                the visibility to reach a new customer base. At 4N we can help
                design, build and deploy a modern, easy-to-use website for
                customers to find information about your business.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{
                  bgcolor: "#CDC392",
                  width: { xs: "25vw", md: "5vw" },
                  height: { xs: "25vw", md: "5vw" },
                  margin: 3,
                }}
              >
                <VideocamOutlinedIcon fontSize="large" />
              </Avatar>
              <Typography
                variant="h4"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                  marginBottom: 2,
                }}
              >
                Video
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Videos can evoke emotion and let customers feel what you as a
                business can provide. Also, if done right, videos can bring in
                viewers and convert them into clients. So contact and let's find
                a way we can incorporate this into your marketing strategy.
                Check out our portfolio!
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{
                  bgcolor: "#D76A03",
                  width: { xs: "25vw", md: "5vw" },
                  height: { xs: "25vw", md: "5vw" },
                  margin: 3,
                }}
                md={{
                  width: "20vw",
                  height: "20vw",
                }}
              >
                <PhotoCameraOutlinedIcon fontSize="large" />
              </Avatar>
              <Typography
                variant="h4"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                  marginBottom: 2,
                }}
              >
                Photo
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                If you need photos of your products or professional headshots of
                your employees, we can help. Partnering with 2.8 Photography, we
                can help provide for your photography needs, no matter how big
                or small. Check out their website 28photography.com
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Provide;
