import React from "react";

//MUI
import { Box } from "@mui/system";

//Style
import "../Style/index.css";

//Images & Logo
import banner from "../asset/cam.jpg";
import Logo from "../3.svg";

const LandingTop = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="bannerContent">
        <Box
          component="img"
          sx={{
            width: { xs: "70vw", md: "30vw" },
          }}
          alt="Logo"
          src={Logo}
        />
      </div>
    </div>
  );
};

export default LandingTop;
