import React from "react";

//MUI
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

//Icon
import LogoImage from "../asset/4n icons/light.svg";

//Images
import peter from "../asset/cover-91.jpg";
import theresa from "../asset/cover-79.jpg";

function Team(props) {
  return (
    <div style={{ minHeight: "50vh", padding: 40 }}>
      <Typography
        variant="h4"
        noWrap
        component="a"
        sx={{
          mr: 2,
          color: "inherit",
          textDecoration: "none",
          marginBottom: 300,
        }}
      >
        Our Team
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems="center" spacing={9}>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{ bgcolor: "#324234", margin: 3, width: 300, height: 300 }}
                variant="rounded"
                src={peter}
              >
                <img alt="Logo" src={LogoImage} style={{ height: "2rem" }} />
              </Avatar>
              <Typography
                variant="h4"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Peter Nguyen
              </Typography>
              <Typography variant="h6" gutterBottom>
                Founder
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{ bgcolor: "#324234", margin: 3, width: 300, height: 300 }}
                variant="rounded"
                src={theresa}
              >
                <img alt="Logo" src={LogoImage} style={{ height: "2rem" }} />
              </Avatar>
              <Typography
                variant="h4"
                component="a"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Theresa Le
              </Typography>
              <Typography variant="h6" gutterBottom>
                Creative Director
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="centerSection">
              <Avatar
                sx={{ bgcolor: "#30000", margin: 3, width: 300, height: 300 }}
                variant="rounded"
              >
                <img alt="Logo" src={LogoImage} style={{ height: "2rem" }} />
              </Avatar>
              <Typography
                variant="h4"
                component="a"
                sx={{
                  mr: 2,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Duy-Dan Nguyen
              </Typography>
              <Typography variant="h6" gutterBottom>
                Director of Cinematography
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Team;
